<template>
  <div class="customise">
    <B2cNavMenu :item-flag="2" :page="page" />
    <h3 class="title" style="margin-top: 38px;margin-bottom: 70px">
      关于产品开发
    </h3>
    <Article :data="containerList" />
    <div class="bg-blue">
      <Article ref="article" :data="containerList2" :reverse="true" />
    </div>

    <div class="com">
      <div class="com-item">
        <img class="com-img" src="../../../assets/img/customise3.png" alt="Vllshop">
        <div class="textBox">
          <p class="title">
            新概念网页商品陈列形式
          </p>
          <div class="line" />
          <p class="text">
            Vllshop设计师会根据用户风格与转化体验、为您设计最合适的商品陈列页面
          </p>
        </div>
      </div>
    </div>
    <div class="com2" style="margin-top: 186px;">
      <div class="container-item reverse">
        <div class="content">
          <p class="title">
            高转化的产品列表页
          </p>
          <div class="line" />
          <p class="text">
            设计师会根据不同的产品类型，设计不同的产品列
          </p>
          <p class="text">
            表页布局已简单清晰的产品罗列方式技巧来提高网站的转化率
          </p>
          <p class="text">
            站的转化率
          </p>
        </div>
        <img class="container-item-img" src="../../../assets/img/customise4.png" alt="Vllshop" style="width: 427px;height: 593px;">
      </div>
    </div>
    <img class="pcImg" src="../../../assets/img/customise5.png" alt="Vllshop科技">
    <h3 class="title" style="margin-bottom: 81px">
      联系我们洽谈需求
    </h3>
    <div class="inputBox">
      <input v-model="yourphone" class="input_phone" placeholder="留下您的联系方式">
      <el-button type="primary" round @click="inputPhone()">
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
import B2cNavMenu from 'components/B2cNavMenu'
import { isvalidPhone } from '@/utils/validate'
import { addPhone } from '@/api/register'
// import BannerStation from 'components/BannerStation'
import Article from 'components/Article'

export default {
  name: 'Customise',
  components: { Article, B2cNavMenu },
  data() {
    return {
      yourphone: '',
      page: 'b2c',
      bgBlueTop: 0,
      bgBlueHeight: 0,
      phone: '',
      containerList: [
        {
          p1: '按您的需求进行定制开发',
          text: ['Vllshop将不断完善功能、与客户沟通需求，并提', '供专业的项目意见经过评估需求、价格、工期、等', '规划，为客户提供一个完整闭环的定制功能开发解', '决方案'],
          imgUrl: require('../../../assets/img/customise1.png')
        }
      ],
      containerList2: [
        {
          p1: '海量模板品牌装饰设计',
          text: ['Vllshop将根据您的品牌形象、客户的标签、设置', '置不同风格的网站'],
          imgUrl: require('../../../assets/img/customise2.png')
        }
      ]
    }
  },
  created() {
    const routerText = this.$router.history.current.path.split('/')[1]
    if (routerText === 'business') {
      this.page = 'business'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.article.$el.children[1]
      console.log([this.$refs.article.$el.children[1]])
    })
  },
  methods: {
    inputPhone() {
      if (!isvalidPhone(this.yourphone)) {
        this.$message.error('格式不对')
        return
      }
      addPhone(this.yourphone).then(res => {
        if (res.code === 0) {
          this.$message.success('提交成功')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  font-size: $titleSize;
  font-weight: 500;
  color: #141414;
}
//banner
.banner_bottom{
  width: 1903px;
  height: 375px;
  cursor: pointer;
}
//
.nav{
  margin-top: 68px;
  margin-bottom: 70px;
}
.com2{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .line {
    background-color: #AB904E;
    width: 130px;
    height: 4px;
  }
  & >.title{
    margin-top: 85px;
    margin-bottom: 54px;
    font-size: $titleSize;
    font-weight: 500;
    color: #141414;
  }
}
.reverse{
  flex-direction: row-reverse;
}
.container-item{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 150px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & .title {
      margin-bottom: 30px;
      line-height: $titleSize;
      font-size: $titleSize;
    }
    & .text {
      font-size: $contentText;
      color: #818478;
    }
  }
  .line{
    margin-bottom: 30px;
  }
  .container-item-img{
    width: 608px;
    height: 448px;
  }
}
.container-item:nth-last-of-type(1){
  margin-bottom: 50px;
}
::v-deep .container-item:nth-of-type(2){
  margin-top: 100px;
  margin-bottom: 80px;
  .content {
    & .title {
      color: #FFFFFF;
      text-align: left;
    }
    & .text{
      color: #FFFFFF;
    }
  }
  .container-item-img{
    width: 600px;
    height: 330.34px;
  }
}
.bg-blue{
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #17345E;
  z-index: -1;
  color: #fff;
  ::v-deep .text{
      color: #fff !important;
  }
  ::v-deep .container-item{
    margin: 20px;
  }
}

.com{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 120px;
  .com-item{
    display: flex;
    width: 1200px;
    position: relative;
  }
  .com-img{
    width: 791px;
    height: 404px;
  }
  .textBox{
    display: flex;
    position: absolute;
    top: 50px;
    right: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .line {
      margin-top: 109px;
      margin-bottom: 35px;
      align-self: center;
      background-color: #AB904E;
      width: 130px;
      height: 4px;
    }
    & .title{
      font-size: $titleSize;
      color: #000;
    }
    & .text{
      font-size: $contentText;
      color: #333F4D;
      width: 306px;
      text-align: left;
    }
  }
}
  .pcImg{
  height: 528px;
  width: 913px;
  margin-top: 270px
}
.inputBox{
  width: 819px;
  height: 141px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto 218px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include Box-shadow(0,0,0,0.46);
  .input_phone{
    width: 610px;
    height: 63px;
    margin-left: 54px;
    font-size: 29px;
    border: none;
    &::placeholder{
      color: #89ACDD;
    }
  }
  .el-button{
    width: 180px;
    height: 48px;
    margin-right: 44px;
    font-size: 20px;
    border-radius: 44px ;
    background-color: #1E70FF;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px){
  .com{
    .com-item{
      width: 980px;
      p.title{
        font-size: 2.5rem;
      }
    }
  }
  .com2{
    .container-item{
      width: 980px;
      p.title{
        font-size: 2.5rem;
      }
    }
  }
}
@media only screen and (min-width:768px) and (max-width:991px){
  .com{
    .com-item{
      width: 750px;
      flex-direction: column-reverse;
      p.title{
        font-size: 2.3rem;
      }
    }
    .textBox{
      position: relative;
      bottom: 20px;
      .line{
        margin-top: 20px;
      }
    }
    .com-img{
      width: 711.9px;
      height: 363.6px;
    }
  }
  .com2{
    .container-item{
      flex-direction: column;
      width: 750px;
      p.title{
        font-size: 2.3rem;
      }
    }
  }
  .pcImg{
    width: 757.79px;
    height: 438.24px;
  }
  .inputBox{
    width: 760px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px){
  .com{
    .com-item{
      width: 570px;
      flex-direction: column-reverse;
      p.title{
        font-size: 2.3rem;
      }
    }
    .textBox{
      position: relative;
      top: 20px;
      .line{
        margin-top: 20px;
      }
    }
    .com-img{
      width: 533.925px;
      height: 272.7px;
    }
  }
  .com2{
    .container-item{
      flex-direction: column;
      width: 570px;
      p.title{
        font-size: 2.3rem;
      }
    }
  }
  .pcImg{
    width: 567.89px;
    height: 328.68px;
  }
  .inputBox{
    width: 570px;
  }
}
@media only screen and (min-width:480px) and (max-width:575px){
  .com{
    .com-item{
      width: 467px;
      flex-direction: column-reverse;
      p.title{
        font-size: 2.1rem;
      }
    }
    .textBox{
      position: relative;
      top: 20px;
      .line{
        margin-top: 20px;
      }
    }
    .com-img{
      width: 437.81px;
      height: 223.6px;
    }
  }
  .com2{
    .container-item{
      flex-direction: column;
      width: 467px;
      p.title{
        font-size: 2.3rem;
      }
    }
  }
  .pcImg{
    width: 465.6px;
    height: 269.4px;
  }
  .inputBox{
    width: 480px;
    height: 93px;
    .input_phone{
      margin-left: 40px;
      font-size: 1.3rem;
    }
  }
}
@media only screen and (min-width:320px) and (max-width:479px){
  .com{
    .com-item{
      width: 320px;
      flex-direction: column-reverse;
      p.title{
        font-size: 1.8rem;
      }
    }
    .textBox{
      position: relative;
      top: 0;
      right: 0;
      .line{
        margin-top: 20px;
      }
    }
    .com-img{
      width: 293.6px;
      height: 149.71px;
    }
  }
  .com2{
    .container-item{
      flex-direction: column;
      width: 320px;
      p.title{
        font-size: 2rem;
      }
      p.text{
        text-align: left;
      }
      .container-item-img{
        width: 298.9px !important;
        height: 415.1px !important;
      }
    }
  }
  .pcImg{
    width: 311.85px;
    height: 180.77px;
  }
  .inputBox{
    width: 320px;
    height: 93px;
    .input_phone{
      width: 200px;
      margin-left: 10px;
      font-size: 1.1rem;
    }
    .el-button{
      width: 80px;
      margin-right: 10px;
      font-size: 1rem;
    }
  }
}
</style>
