/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 文章快速生成组件
*/
<template>
  <a-row class="containerBox" justify="center" type="flex" >
    <h3 v-if="title" class="title">
      {{ title }}
    </h3>
    <a-row v-for="(item,index) in containerList" :key="'containerItem' + index" :ref="'containerItem' + index" :class="{'container-item':true,'reverse':index%2==r}" :gutter="24" justify="center" type="flex">
      <a-col class="content"  lg="12" md="12" sm="12" xl="5" xs="12">
        <p v-if="item.p1" class="title">
          {{ $t(item.p1) }}
        </p>
        <p v-if="item.p2" class="title">
          {{ $t(item.p2) }}
        </p>
        <div class="line" />
        <p v-for="(e,i) in item.text" :key="i" class="text">
          {{ $t(e) }}
        </p>
        <div v-show="showBuleTxt && index==0" style="color:#028BFF;margin-top: 10px;font-size: 19px;cursor: pointer;" @click="toLogin()">更多模板选择 →</div>
      </a-col>
      <a-col class="bimg-box" cols="12" lg="12" md="12" sm="12" xl="7" xs="12">
        <b-img class="bimg wow fadeInUp" :src="item.imgUrl" fluid alt="Responsive image" data-wow-delay="0.3s" />
      </a-col>
    </a-row>
  </a-row>
</template>

<script>
export default {
  name: 'Article',
  props: {
    showBuleTxt: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      containerList: [],
      r: 0
    }
  },
  created() {
    this.containerList = this.data
    if (this.reverse) { this.r = 1 }
  },
  mounted() {
    new this.$wow.WOW().init()
  },
  methods: {
    toLogin() {
      this.$router.push({ path: '/register', query: { registerType: 'register' }})
    }
  }
}
</script>

<style lang="scss" scoped>
p.title{
  text-align: left;
}
@media (max-width: 576px) {
  .containerBox{
    width: 100% !important;
  }
  .bimg{
    width: 31rem !important;
    max-height: 21rem;
  }
  .bimg-box{
    display: flex;
    justify-content: center;
  }
  .content{
    & >.title{
      font-size: 2.7rem !important;
      margin-bottom: 10px !important;
    }
    & >.text{
      font-size: 1.44rem !important;
    }
  }
}

//container
.containerBox{
  .line {
    background-color: #AB904E;
    width: 130px;
    height: 4px;
  }
  & >.title{
    word-wrap: normal;
    display: flex;
    margin-top: 85px;
    margin-bottom: 54px;
    font-size: $titleSize;
    font-weight: 500;
    color: #141414;
  }
}
.reverse{
  flex-direction: row-reverse;
}
.container-item{
  align-items: center;
  margin-bottom: 52px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & .title {
      margin-bottom: 30px;
      line-height: $subTitleSize;
      font-size: $subTitleSize;
    }
    & .text {
      font-size: $contentText;
      color: #818478;
      text-align: left;
    }
  }
  .line{
    margin-bottom: 30px;
  }
  .container-item-img{
    width: auto;
    height: auto;
  }
}
</style>
