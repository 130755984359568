/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 导航条
*/

<template>
  <div class="nav">
    <ul class="navMenu">
      <li v-for="(item,index) in TextList" :key="'B2cItem' + index" class="navMenu-item">
        <div v-show="index == Flag" class="selected-round" />
        <p :class="['title', {'selected':index == Flag}]" @click="handleNavItem(index)">
          {{ item.title }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'B2cNavMenu',
  props: {
    itemFlag: {
      type: Number,
      default: 0
    },
    page: {
      type: String,
      default: 'b2c'
    },
    itemTextList: {
      type: Array,
      default: () => {
        return [
          { title: '商店模板',
            path: '/Template'
          },
          { title: '服务选购',
            path: '/b2c'
          },
          { title: '服务亮点',
            path: '/b2c/feature'
          },
          { title: '标准化模块',
            path: '/b2c/module'
          },
          { title: '研发定制',
            path: '/b2c/customise'
          },
          { title: '平台MRD',
            path: '/b2c/mrd'
          }]
      }
    }
  },
  data() {
    return {
      Flag: 0,
      TextList: []
    }
  },
  created() {
    this.Flag = this.itemFlag
    if (this.page === 'b2c') {
      this.TextList = this.itemTextList
    }
    if (this.page === 'business') {
      this.TextList = [
        { title: '功能模块',
          path: '/business/module'
        },
        { title: '商店模板',
          path: '/Template'
        },
        { title: '定制开发',
          path: '/business/customise'
        }
      ]
    }
  },
  methods: {
    handleNavItem(index) {
      this.$router.push(this.TextList[index].path)
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width:992px) and (max-width:1199px){
  .navMenu{
    width: 900px !important;
  }
}
$navFontSize : 25px;
.nav{
  padding: 0 20px;
  display: flex;
  justify-content: center;
}
.navMenu{
  width: 1100px;
  display: flex;
  justify-content: space-between;
  .navMenu-item{
    position: relative;
    height: 47px;
    display: flex;
    cursor: pointer;
    align-items: flex-end;
    & .title{
      color: #89ACDD;
      font-size: $navFontSize;
      position: relative;
    }
    & .title.selected{
      color: #141414;
    }
    .selected-round{
      width: 29px;
      height: 29px;
      position: absolute;
      background-color: #E29206;
      right: -15px;
      top: 0px;
      border-radius: 50%;
    }
  }
}

</style>
